import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PaginationPerPageOption } from '../../models/pagination-per-page-option';

@Component({
  selector: 'az-table-per-page',
  templateUrl: './az-table-per-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzTablePerPageComponent {
  public readonly BASE_TRANSALATE = 'shared.table';
  @Input() public selectItemDefault: number = 200;
  public _options: Array<PaginationPerPageOption> = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200', selected: true },
  ];

  @Output() public changeItemsPerPage = new EventEmitter<number>();

  public get options(): Array<PaginationPerPageOption> {
    return this._options.map((trae) => {
      trae.selected = false;
      if(parseInt(trae.label)==this.selectItemDefault){
        trae.selected = true;
      }
      return trae;
    });
  }

  public itemsPerPageChanged(event: any) {
    const limit = parseInt(event.target.value);

    this.changeItemsPerPage.emit(limit);
  }
}
